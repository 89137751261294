@import "../../scss/variables";

.headerStyleDark {
  padding-top: 10px;
  background-color: $footer-header-color-darkmode;
  height: 60px;
}

.headerStyleLight {
  padding-top: 10px;
  background-color: $footer-header-color-lightmode;
  height: 60px;
}

.imgCircle {
  border-radius: 50%;
  height: 40px;
  background-color: transparent;
  border: 0;
}

.nav {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  li a {
    color: white;
    color: #{$secondary-color};
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.06em;
    // text-transform: uppercase;
    position: relative;
    padding: 1.1rem 1.2rem;
    text-decoration: none;
    // border: 1px solid red;
  }

  li:not(:first-of-type) {
    a:hover,
    a[aria-current="page"] {
      text-decoration: none;
      color: #{$nav-color-hover};
      &:after {
        content: "";
        display: block;
        position: absolute;
        background: #{$nav-color-hover};
        width: 100%;
        height: 2px;
        bottom: 0;
      }
    }
  }
}

.btnGhostLight {
  --cui-btn-color: black !important;
  --cui-btn-hover-bg: white !important;
  --cui-btn-hover-border-color: white !important;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95) !important;
  --cui-btn-active-bg: #bcbebf;
  --cui-btn-active-border-color: #b0b2b3;
  --cui-btn-active-color: rgba(44, 56, 74, 0.95);
  --cui-btn-disabled-color: #ebedef;
  --cui-btn-shadow: rgba(235, 237, 239, 0.5);
}

.btnGhostDark {
  --cui-btn-color: black !important;
  --cui-btn-hover-bg: white !important;
  --cui-btn-hover-border-color: white !important;
  --cui-btn-hover-color: #{$stealth-darkgray} !important;
  --cui-btn-active-bg: white !important;
  --cui-btn-active-border-color: white;
  --cui-btn-active-color: black;
  --cui-btn-disabled-color: #4f5d73;
  --cui-btn-shadow: rgba(79, 93, 115, 0.5);
}

.hamburgerStyle {
  text-decoration: none;
  list-style-type: none;
  color: #{$stealth-black};
  width: 100%;
  height: 100%;
  display: block;
  &:hover {
    text-decoration: none;
    list-style-type: none;
    color: #{$nav-color-hover};
  }
  &:active {
    text-decoration: none;
    list-style-type: none;
    color: #{$nav-color-hover};
    background-color: #{$secondary-color};
  }
}

.tabStyle {
  text-align: center;
  &:active {
    background-color: #{$secondary-color} !important;
    margin-left: auto;
  }
}
